import React, { useRef } from 'react'
import { Box, Text, FormField, TextInput, TextArea, Button } from 'grommet'
import { navigateTo } from 'gatsby-link'
import { values as _values, head } from 'lodash/fp'
import * as Yup from 'yup'

import Layout from '../components/layout'
import Heading from '../components/Heading'
import FormErrorMessage from '../components/FormErrorMessage'
import { Formik, Field, Form } from 'formik'
import SEO from '../components/seo'
import ConstrainedContainer from '../components/ConstrainedContainer'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('email is required'),
  name: Yup.string().required('name is required'),
  message: Yup.string().required('message is required'),
})

const ThankYou = () => {
  const formRef = useRef(null)
  return (
    <Layout>
      <SEO title="Contact" />
      <Box pad={{ vertical: 'large' }}>
        <Heading level={2} textAlign="center" alignSelf="center">
          How can we help?
        </Heading>
        <ConstrainedContainer inner>
          <Formik
            validationSchema={ContactSchema}
            initialValues={{ name: '', email: '', message: '' }}
            onSubmit={(values, formik) => {
              const form = formRef.current
              fetch('/', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: encode({
                  'form-name': 'contact',
                  ...values,
                }),
              })
                .then(() => navigateTo(form.getAttribute('action')))
                .catch(error => {
                  console.log(error)
                  formik.setErrors(error)
                })
            }}
            render={({ isValid, handleSubmit, handleChange, errors }) => (
              <form
                name="contact"
                method="POST"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                ref={formRef}
                onSubmit={handleSubmit}
              >
                <FormField label="Name">
                  <TextInput
                    name="name"
                    type="name"
                    id="name"
                    onChange={handleChange}
                  />
                </FormField>
                <FormField label="Email">
                  <TextInput
                    name="email"
                    type="email"
                    id="email"
                    onChange={handleChange}
                  />
                </FormField>
                <FormField label="Message">
                  <TextArea
                    name="message"
                    id="message"
                    onChange={handleChange}
                  />
                </FormField>
                <Field type="hidden" name="bot-field" />
                <Box>
                  <Button
                    disabled={!isValid}
                    primary
                    label="Submit"
                    type="submit"
                  />
                  <FormErrorMessage error={head(_values(errors))} />
                </Box>
              </form>
            )}
          />

          <Text size="small" margin={{ vertical: 'medium' }}>
            Or email us at{' '}
            <a href="mailto:hello@modernmail.co">hello@modernmail.co</a>
          </Text>
        </ConstrainedContainer>
      </Box>
    </Layout>
  )
}

export default ThankYou
